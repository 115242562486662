import FInteg from "../firebaseable/index.js.erb"
import Rails from "@rails/ujs"
import PWDHandler from "./pwd_handler"



$(document).on 'turbolinks:load', () ->
  new FInteg('new_user', 'signin');

  new FInteg('google', 'google');

  new FInteg('facebook', 'facebook');

  # new FInteg('linkedin', 'linkedin');

  if($('#form-forgot').length > 0)
    new FInteg('form-forgot', 'forgot');


  new PWDHandler()