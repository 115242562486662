import Rails from "@rails/ujs"
import * as firebase from "firebase/app";
require("firebase/auth");
require("firebase/database");
require("firebase/functions");
import "bootstrap/js/dist/modal";

export default class FInteg {
  constructor(form_tag_id, auth_type) {

    if (!firebase.apps.length)
      firebase.initializeApp(this._config());

    
    if(auth_type != "logout")
      this.form = $(`#${form_tag_id}`);
    else
      this.form = $(`.${form_tag_id}`);

    this.auth_type = auth_type;

    this.submit_btn = this.form.find('button[type="submit"]');
    
    if(this.submit_btn.length > 0) {
      const btn_txt = this.submit_btn.html().to_string
      this.submit_btn_text = btn_txt;

      this._before_send();
    }

    this._bind_submit();

    this._auth_state_changed();

    if(this.auth_type == 'linkedin_auth'){
      this.getURLParameter = this.getURLParameter.bind(this);
      this.tokenReceived = this.tokenReceived.bind(this);
      this.getFirebaseProjectId = this.getFirebaseProjectId.bind(this);
    }

    window._inValidAuth = this._inValidAuth.bind(this);
  }


  _bind_submit() {
    switch(this.auth_type) {
      case 'signin':
        this._reg_sign_in();
        break;
      case 'signup':
        this._reg_sign_up();
        break;
      case 'google':
        this._google();
        break;
      case 'facebook':
        this._facebook();
        break;
      case 'linkedin':
        this._linkedin();
        break;
      case 'changepass': 
        this._change_pass();
        break;
      case 'forgot':
        this._forgot_pass();
        break;
      case 'logout':
        this._sign_out();
        break;
    }
  }


  _before_send() {
    if(this.form.data('remote')) {
      const form_id = this.form.attr('id');
      $(document).on("ajax:beforeSend", `form#${form_id}`, (e, data, status, xhr) => {
        // const token = $(`form#${form_id}`).find('#token').val()
        // const not_accepted = token == undefined || token == ""
        // if(this.auth_type == 'signin' && not_accepted) {
        //   return false
        // } else {
        //   return true
        // }

        return false
      });
    }
  }


  _auth_state_changed() {
    firebase.auth().onAuthStateChanged( user => {
      // if (user && allowRedirect) {
      //   window.location.href = window.location.origin + '/accounts/cubes';
      // }

      if (user){
        console.log("signed in!")
        // if($('.login-btn').is(':visible'))
        //   Turbolinks.visit("/")
      }
      else {
        // console.log("signed_out")
        if($('.profile-btn').is(':visible'))
          $('.logout')[0].click();
      }
    });
  }


  _sign_out() {
    const _this = this;
    this.form.on('click', e => {
      if(firebase.auth().currentUser != null) {
        Rails.stopEverything(e);
        firebase.auth().signOut();
      }
    });
  }


  _reg_sign_in() {
    const _this = this;
    this.form.submit( (e) => {
      const token = this.form.find('#token').val()
      if(token != undefined && token != "") return true 

      e.preventDefault();

      _this.form.parent('.modal-body').find('.alert-warning').remove();
      _this.form.parent('.modal-body').find('.alert-danger').remove();

      const email       = _this.form.find('#user_email'),
            password    = _this.form.find('#user_password');
      let   success     = true;

      if($.trim(email.val()) == "" || password.val() == ""){
        _this._inValidAuth("Invalid email or password.")
        email.val('');
        password.val('');
        email.focus();
        _this._stop_load_btn();
      } else if(!_this.validateEmail($.trim(email.val()))) {
        _this._inValidAuth("Email format is invalid.", 'alert-danger')
        email.val('');
        password.val('');
        email.focus();
        _this._stop_load_btn();
      } else {
        _this._signInUser({
          email: $.trim(email.val()),
          password: password.val()
        }, email, password);
      }
    });
  }


  _forgot_pass() {
    const _this = this;
    this.form.submit( (e) => {
      Rails.stopEverything(e);

      var auth = firebase.auth(),
          success = true,
          email = _this.form.find('#forgot_user_email');

      _this._unerror_field(email);
      _this.form.find('.alert').remove();

      if(!_this.validateEmail($.trim(email.val()))) {
        _this._has_error_field(email, "Please enter a valid email address.");
      } else {
        _this._load_btn();

        firebase.auth().fetchSignInMethodsForEmail($.trim(email.val())).then( (prov) => {
          if(prov[0] == 'google.com' || prov[0] == 'facebook.com'){
            _this._has_error_field(
              email, 
              "The email entered is used by a provider (" + prov[0] + ") \
              other than CollaVR. It is advised to reset the password on that \
              provider."
            );

            _this._stop_load_btn();
          } else {
            firebase.auth().sendPasswordResetEmail($.trim(email.val())).then( () => {
              _this._inValidAuth("An email has been sent to reset your password!", 'alert-success');
              // $('#form-forgot').find('input').attr('disabled', true);
              // $('#form-forgot').find('button').attr('disabled', true);
              _this.form[0].reset();
              _this._stop_load_btn();
            }).catch(function(error) {
              _this._has_error_field(email, error.message);
              _this._stop_load_btn();
            });
          }
        });
      }
    });
  }


  notify_email_sent() {
    if(!this.form.children('.alert.alert-success').is(':visible'))
      formCont.prepend(
        '<div class="alert alert-success" role="alert">' +
          '<strong>An email has been sent to reset your password!</strong>' +
        '</div>'
      );
  }


  _reg_sign_up() {
    const _this = this;
    this.form.submit( (e) => {
      if(_this.form.find('#user_uid').val() == "") {
        Rails.stopEverything(e);
        _this._load_btn();
      
        $('.alert').remove();

        let errors_cnt  = 0,
            username    = _this.form.find('#user_username'),
            email       = _this.form.find('#register_user_email'),
            password    = _this.form.find('#register_user_password'),
            cPassword   = _this.form.find('#user_password_confirmation'),
            firstname   = _this.form.find('#user_firstname'),
            lastname    = _this.form.find('#user_lastname'),
            country     = _this.form.find('#user_country'),
            address     = _this.form.find('#user_address1'),
            uid         = _this.form.find('#user_uid'),
            whats_app   = _this.form.find('#user_whats_app_num'),
            success     = true;

          
        if(email.val() == "") {
          errors_cnt = errors_cnt+=1;
          _this._has_error_field(email, "Email can't be blank");
          success = false;
        } else if(!_this.validateEmail($.trim(email.val()))) {
          errors_cnt = errors_cnt+=1;
          _this._has_error_field(email, "Email format is invalid");
          success = false;
        } else {
          _this._unerror_field(email);
        }


        if(password.val() == "") {
          errors_cnt = errors_cnt+=1;
          success = false;
          _this._has_error_field(password, "Password can't be blank");
        } else _this._unerror_field(password);


        var w_app = new RegExp(/\+\(\d{1,4}\)\s\d{3}\s\d{3}\s\d{4}/s);
        if(!w_app.test(whats_app.val()) && whats_app.val() != "") {
          errors_cnt = errors_cnt+=1;
          success = false;
          _this._has_error_field(whats_app, "Invalid phone number");
        } else _this._unerror_field(whats_app);


        if(cPassword.val() == "") {
          errors_cnt = errors_cnt+=1;
          success = false;
          _this._has_error_field(cPassword, "Confirmation password can't be blank");
        } else if(cPassword.val() != password.val()) {
          errors_cnt = errors_cnt+=1;
          success = false;
          password.val('')
          cPassword.val('')
          _this._has_error_field(cPassword, "Password confirmation does not match password");
        } else _this._unerror_field(cPassword);


        if(firstname.val() == ""){
          errors_cnt = errors_cnt+=1;
          success = false;
          _this._has_error_field(firstname, "Firstname can't be blank");
        } else _this._unerror_field(firstname);


        if(lastname.val() == ""){
          errors_cnt = errors_cnt+=1;
          success = false;
          _this._has_error_field(lastname, "Lastname can't be blank");
        } else _this._unerror_field(lastname); 
      

        if(success) {
          // to be worked on
          let userDetail = {
                email       : $.trim(email.val()),
                password    : $.trim(password.val()),
                bio         : "",
                firstname   : $.trim(firstname.val()),
                // middle      : $.trim(middlename.val()),
                lastname    : $.trim(lastname.val()),
                'gamer-tag' : "",
                country     : $.trim(country.val()),
                phone       : "",
                address     : $.trim(address.val()),
                image       : "",
                status      : ''
                // identity    : $.trim(identity.val()),
              }
          
          // url = window.location.origin +  $('#form-sign-up').attr('action');
          userDetail = $.extend(userDetail, _this._auth_extender("", null));
          _this._signUpUser(userDetail);
        } else {
          _this.form.before(_this._got_issues(errors_cnt));
          _this._stop_load_btn();

          $('html, body').animate({
            scrollTop: $('html, body').offset().top + 274
          }, 'slow');
        }
      }
    });
  }


  _got_issues(errors=1, message=undefined) {
    let error = 'error'

    if(errors > 1) error = 'errors'

    let msg = `${errors} ${error} prohibited this user from being saved.`;

    if(message != undefined)
      msg = message;

    return `<div class="alert alert-danger mb-4" role="alert">
      <div class="alert-text">
        <h4 class="mb-3 alert-heading">Got Issues!</h4>
        <p class="mb-3">
          ${msg}
        </p>
      </div>
    </div>`;
  }


  _auth_extender(provider, value) {
    return {
      bgImg       : '',
      twitterId   : provider.toLowerCase() == 'twitter'       ? value : '',
      facebookId  : provider.toLowerCase() == 'facebook.com'  ? value : '',
      googleId    : provider.toLowerCase() == 'google.com'    ? value : '',
      snapchatId  : provider.toLowerCase() == 'snapchat'      ? value : '',
      linkinId    : provider.toLowerCase() == 'linkedin.com'        ? value : ''
    };
  }


  _has_error_field(feild, msg) {
    feild.addClass('is-invalid');
    if(msg !== "")
      this._control_feedback(feild.parent('div'), msg);
  }

  _control_feedback(controlElem, msg) {
    if (!controlElem.children("small").is(':visible')) {
      controlElem.append('<small class="invalid-feedback d-block">' +
        msg +
        '</small>'
      );
    } else {
      controlElem.children("small").html(msg);
    }
  }


  _unerror_field(field) {
    field.removeClass('is-invalid');
    field.parent('div').children('small').remove();
  }


  _signUpUser(profile) {
    const _this = this;
    // this.form[0].submit();
    firebase.auth().createUserWithEmailAndPassword(profile.email, profile.password)
    .then(function(result){
      delete profile.password;

      const user = result.user

      _this.form.find('#user_uid').val(user.uid);

      profile = $.extend(profile, {userId: user.uid});
      profile = $.extend(profile, _this._default_extra_fields());

      const addUser = firebase.database().ref(["Users", user.uid].join('/'));
      addUser.set(profile, () => {
        _this.form.find("#register_user_password").val(user.uid)
        _this.form.find("#user_password_confirmation").val(user.uid)
        _this.form[0].submit();
      });

    }).catch(function(error) {
      let email = _this.form.find("#register_user_email"),
          password    = _this.form.find('#register_user_password'),
          cPassword   = _this.form.find('#user_password_confirmation');

      if(error.code === "auth/email-already-in-use") {
        _this._has_error_field(email, "Email already in use.");
        _this.form.before(_this._got_issues());
      } else if(error.code === "auth/weak-password") {
        _this._has_error_field(password, error.message);
        _this.form.before(_this._got_issues());
      } else {
        // console.log("error");
        // console.log(error);
        _this.form.before(_this._got_issues(1, error.message));
      }

      _this._stop_load_btn();
      password.val('');
      cPassword.val('');

      $('html, body').animate({
        scrollTop: $('html, body').offset().top + 274
      }, 'slow');
    });
  }


  _default_extra_fields() {
    return {
      'age-group'       : "",
      'favorite-color'  : "",
      'favorite-genre'  : "",
      occupation        : ""
    };
  }


  _signInUser(userDetail, email_elem, pass_elem) {
    this._load_btn();

    const email   = userDetail.email,
        password  = userDetail.password;

    const _this = this;

    firebase.auth().fetchSignInMethodsForEmail(email).then( prov => {
      if(prov[0] === 'password' || prov.length <= 0) {
        firebase.auth().signInWithEmailAndPassword(email, password)
          .then( result => {
            const user = result.user;
            // $('#myModal').modal('hide')

            firebase.database().ref('Users/' + user.uid).once('value').then( snapshot => {
              const user_details = snapshot.val();

              _this._signin_as_provider(user.uid, user_details, user.providerData[0].providerId)
            });
          }).catch( error => {
            this._inValidAuth(error.message);
            email_elem.val('');
            pass_elem.val('');
            email_elem.focus();
            _this._stop_load_btn();
          });
      } else {
        prov[0] = prov[0].replace('.com', '');
        var msg = "The email was registered through " +
                  prov[0].replace(prov[0][0], prov[0][0].toUpperCase()) +
                  ". Please sign in with that provider.";
        _this._inValidAuth(msg);
        email_elem.val('');
        pass_elem.val('');
        email_elem.focus();
        _this._stop_load_btn();
      }
    }).catch( error => {
      const errorCode = error.code,
            errorMessage = error.message;

      _this._inValidAuth(errorMessage, 'alert-danger');

      _this._stop_load_btn();
    });
  }


  _google() {
    const _this = this;
    const login_btn = $('.login-btn[data-target="#myModal"]');

    this.form.click((e) => {
      e.preventDefault();
      if(login_btn.length > 0) login_btn.trigger('click');

      const gprovider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(gprovider).then(function(result) {
        // console.log(result);
        var token     = result.credential.accessToken,
            user      = result.user,
            provider  = result.additionalUserInfo.providerId,
            profInfo  = result.additionalUserInfo.profile;

        firebase.database().ref('Users/' + user.uid).once('value').then(function(snapshot) {
          if(!snapshot.val()) {
            var profile = {
              bio         : '',
              email       : profInfo.email,
              firstname   : profInfo.given_name ? profInfo.given_name : '',
              // middle      : '',
              lastname    : profInfo.family_name ? profInfo.family_name : '',
              'gamer-tag' : '',
              country     : '',
              address     : '',
              phone       : '',
              image       : profInfo.picture? profInfo.picture : '',
              status      : ''
            };

            profile = $.extend(profile, _this._auth_extender(provider, profInfo.id));
            profile = $.extend(profile, {userId: user.uid});
            profile = $.extend(profile, _this._default_extra_fields());

            var addUser = firebase.database().ref(["Users", user.uid].join('/'));
            addUser.set(profile, function() {
              _this._signin_as_provider(user.uid, profInfo, provider);
            });
          } else {
            var usr         = snapshot.val(),
                updateUser  = firebase.database().ref(["Users", user.uid].join('/'));
            updateUser.update({
              firstname   : profInfo.given_name ? profInfo.given_name : usr.firstname,
              lastname    : profInfo.family_name ? profInfo.family_name : usr.lastname,
              image       : profInfo.picture? profInfo.picture : '',
              status      : 'online'
            }, function() {
              _this._signin_as_provider(user.uid, profInfo, provider);
            });
          }
        });
      }).catch(_this._provider_auth_err);
    });
  }


  _facebook() {
    const _this = this;
    const login_btn = $('.login-btn[data-target="#myModal"]');

    this.form.click((e) => {
      e.preventDefault();
      if(login_btn.length > 0) login_btn.trigger('click');

      var fbprovider = new firebase.auth.FacebookAuthProvider();
      firebase.auth().signInWithPopup(fbprovider).then(function(result) {
        // console.log(result);
        var token     = result.credential.accessToken,
            user      = result.user,
            provider  = result.additionalUserInfo.providerId,
            profInfo  = result.additionalUserInfo.profile;

        firebase.database().ref('Users/' + user.uid).once('value').then(function(snapshot) {
          var redirectUrl = window.location.origin + '/accounts/cubes';

          if(!snapshot.val()) {
            var profile = {
              bio         : '',
              email       : profInfo.email,
              firstname   : profInfo.first_name ? profInfo.first_name : '',
              lastname    : profInfo.last_name ? profInfo.last_name : '',
              'gamer-tag' : '',
              country     : '',
              address     : '',
              phone       : '',
              image       : user.photoURL ? user.photoURL : '',
              status      : ''
            };

            profile = $.extend(profile, _this._auth_extender(provider, profInfo.id));
            profile = $.extend(profile, {userId: user.uid});
            profile = $.extend(profile, _this._default_extra_fields());

            var addUser = firebase.database().ref(["Users", user.uid].join('/'));
            addUser.set(profile, function() {
              _this._signin_as_provider(user.uid, profInfo, provider);
            });
          } else {
            var usr         = snapshot.val(),
                updateUser  = firebase.database().ref(["Users", user.uid].join('/'));
            updateUser.update({
              firstname   : profInfo.first_name ? profInfo.first_name : usr.firstname,
              lastname    : profInfo.last_name ? profInfo.last_name : usr.lastname,
              image       : user.photoURL ? user.photoURL : '',
              status      : ''
            }, function() {
              _this._signin_as_provider(user.uid, profInfo, provider);
            });
          }
        });
      }).catch(_this._provider_auth_err);
    });
  }


  _linkedin() {
    const _this = this;
    const login_btn = $('.login-btn[data-target="#myModal"]');

    this.form.click((e) => {
      e.preventDefault();
      if(login_btn.length > 0) login_btn.trigger('click');
      
      window.open('/linkedin_auth', 'name', 'height=585,width=550');
    });
  }


  _provider_auth_err(error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.email;
    const credential = error.credential;

    if( errorCode === "auth/account-exists-with-different-credential" ) {
      window._inValidAuth(error.message);
    } else {
      // console.log(error)
      console.log(errorMessage);
    }
  }


  _signin_as_provider(uid, user, provider) {
    let sign_parms = {};
    let rem_me_val = '0';
    const rem_me_elem = this.form.find("#user_remember_me");

    // console.log(uid)
    // console.log(user)

    if(rem_me_elem.length > 0)
      rem_me_val = rem_me_elem.is(':Checked') ? '1' : '0';

    switch(provider) {
      case 'password':
        sign_parms = $.param({
          token: uid,
          user: {
            email: user.email,
            password: uid,
            firstname:  user.firstname,
            lastname: user.lastname,
            provider: provider,
            remember_me: rem_me_val
          }
        });
        break;
      case 'google.com':
        sign_parms = $.param({
          token: uid,
          user: {
            email: user.email,
            password: user.id,
            firstname:  user.given_name,
            lastname: user.family_name,
            provider: provider,
            remember_me: rem_me_val
          }
        });
        break;
      case 'facebook.com':
        sign_parms = $.param({
          token: uid,
          user: {
            email: user.email,
            password: user.id,
            firstname:  user.first_name,
            lastname: user.last_name,
            provider: provider,
            remember_me: rem_me_val
          }
        });
        break;
    }

    Rails.ajax({
      url: "/users/login", 
      type: 'post',
      dataType: 'script',
      data: sign_parms
    })
  }


  _change_pass() {
    const _this = this;

    this.form.submit((e) => {
      e.preventDefault();
      this._load_btn();
      const user = firebase.auth().currentUser;

      user.updatePassword(_this.form.find("#user_password").val()).then(function() {
        // Update successful.
        _this._inValidAuth("Successfully updated password!", 'alert-success');
        _this._stop_load_btn();
        _this.form.find("#user_password").val('');
        _this.form.find("#user_password_confirmation").val('');
        $('html, body').animate({
          scrollTop: $('html, body').offset().top + 274
        }, 'slow');
      }).catch(function(error) {
        _this._inValidAuth(error.message);
        _this._stop_load_btn();
        _this.form.find("#user_password").val('');
        _this.form.find("#user_password_confirmation").val('');
        $('html, body').animate({
          scrollTop: $('html, body').offset().top + 274
        }, 'slow');
      });
    });
  }


  _inValidAuth(msg, a_class='alert-warning') {
    let parent_elem = this.form;

    if(this.auth_type == 'changepass')
      parent_elem = this.form.closest('#change-pass');
    else if( this.auth_type == "facebook" || this.auth_type == "google")
      parent_elem = $("#new_user");

    const alert = parent_elem.children('.alert')
    let str_cont = "Opps!";
    
    if(alert.is(':visible')) alert.remove();

    if(a_class == 'alert-success')
      str_cont = "Yhey!"

    parent_elem.prepend(`
      <div class="alert ${a_class} alert-dismissible fade show" role="alert">
        <strong>${str_cont}</strong> ${msg}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    `);
  }

  validateEmail(email) {
    var eValidation = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return eValidation.test(email);
  }


  _config() {
    let crds = {};
    
    
      crds =  {
        apiKey: 'AIzaSyChqm1-IQsuOA45oySikFi_IvUhyxY2hCM',
        authDomain: 'check-yo-self-18682434.firebaseapp.com',
        databaseURL: 'https://check-yo-self-18682434.firebaseio.com',
        projectId: 'check-yo-self-18682434',
        storageBucket: 'check-yo-self-18682434.appspot.com',
        messagingSenderId: '177025070177',
        appId: '',
        measurementId: ''
      };
    

    return crds;
  }


  _load_btn() {
    this.submit_btn.html(this.submit_btn.data('disable-with'));
    this.submit_btn.attr('disabled', 'disabled')
  }


  _stop_load_btn() {
    switch(this.auth_type) {
      case 'signin':
        this.submit_btn.removeAttr('disabled');
        this.submit_btn.html("Login");
        break;
      case 'signup':
        this.submit_btn.prop('disabled', false);
        this.submit_btn.html("Make Account");
        break;
      case 'changepass':
        this.submit_btn.removeAttr('disabled');
        this.submit_btn.html("Change Password");
        break;
      case 'forgot':
        this.submit_btn.prop('disabled', false);
        this.submit_btn.html("Send me reset password instructions");
        break;
    }
  }

  getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) ||
        [null, ''])[1].replace(/\+/g, '%20')) || null;
  }


  getFirebaseProjectId() {
    return firebase.app().options.authDomain.split('.')[0];
  }


  tokenReceived(data) {
    if (data.token) {
      firebase.auth().signInWithCustomToken(data.token).then(function() {
        window.close();
      });
    } else {
      console.error(data);
      document.body.innerText = 'Error in the token Function: ' + data.error;
    }
  }
}