export default class PWDHandler {
  constructor() {
    this.button_toggler = $("span.pwd-toggler");
    this.input = this.button_toggler.closest('.input-group-append').prev();
    this.bind_actions();
  }

  bind_actions() {
    let _this = this;
    this.button_toggler.click(function() {
      if(_this.input.attr("type") == 'password') {
        _this.input.prop({type: 'text'})
      } else {
        _this.input.prop({type: 'password'})
      }
      _this.button_toggler.children('i').toggleClass( 'fa-eye-slash', 'fa-eye-slash' )
    });
  }
}